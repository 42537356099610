import React from 'react';
import { FaFilePdf, FaFileWord } from 'react-icons/fa';

import shortid from 'shortid';

import tw from 'twin.macro';

import { useSeo, useMotions } from '@queries';
import { Layout } from '@layouts';
import { Section, Markdown, Subheading, Option } from '@components';

const MotionsPage = () => {
    const { description_motions } = useSeo();
    const { _rawText, motions } = useMotions();

    const siteTitle = 'Wnioski';

    return (
        <Layout title={siteTitle} description={description_motions}>
            <Section main heading={siteTitle} spaceTop>
                <Markdown justify blocks={_rawText} />
                <div css={tw`lg:flex flex-wrap justify-between mt-20`}>
                    {motions.map(motion => (
                        <article
                            key={shortid.generate()}
                            css={tw`bg-white flex to-xs:flex-col lg:flex-col justify-between items-center w-full lg:w-7/15 mb-12 last:mb-0 p-10 to-xs:border-t-2 xs:border-l-4 lg:border-t-2 lg:border-l-0 border-accent shadow-md rounded-br-default`}
                        >
                            <Subheading
                                css={tw`to-xs:pb-8 to-xs:-mr-8 lg:pb-8 to-xs:text-center xs:text-left lg:text-center to-lg:pr-8`}
                            >
                                {motion.title}
                            </Subheading>
                            <div css={tw`flex`}>
                                <Option
                                    icon={FaFilePdf}
                                    option=".pdf"
                                    href={motion.pdf.asset.url}
                                    download={motion.title}
                                    title="Pobierz .pdf"
                                    css={tw`mr-4`}
                                />
                                <Option
                                    icon={FaFileWord}
                                    option=".doc"
                                    href={motion.doc.asset.url}
                                    download={motion.title}
                                    title="Pobierz .doc"
                                />
                            </div>
                        </article>
                    ))}
                </div>
            </Section>
        </Layout>
    );
};

export default MotionsPage;
